<template>
  <card-container>
    <div class="flex flex-col items-center justify-center p-8">
      <div class="flex w-full text-sm">
        <button class="text-gray-400">Snarveier</button>
        <div class="flex-grow"></div>
        <button v-if="user" @click="signOut()">
          Logg ut
        </button>
      </div>
      <button class="flex mt-2 overflow-hidden rounded-full w-28 h-28 focus:outline-none">
        <img
          class="w-full h-full cursor-pointer"
          :src="user && user.photoURL ? user.photoURL : 'img/user-blank.png'"
          alt="User photo"
          @click="
            $router.push({
              name: user ? 'user' : 'register',
              params: { transition: 'slide-forward' },
            })
          "
        />
      </button>
      <button
        v-if="user"
        class="mt-2 font-bold focus:outline-none"
        @click="$router.push({ name: 'user', params: { transition: 'slide-forward' } })"
      >
        {{ user.displayName }}
      </button>
      <div v-else class="mt-2">
        <button
          @click="$router.push({ name: 'register', params: { transition: 'slide-forward' } })"
          class="text-blue-500 focus:outline-none"
        >
          Opprett bruker
        </button>
        eller
        <button
          @click="$router.push({ name: 'signin', params: { transition: 'slide-forward' } })"
          class="text-blue-500 focus:outline-none"
        >
          logg inn
        </button>
      </div>
      <div class="flex flex-col w-full gap-2 mt-6">
        <button
          :disabled="!user"
          @click="
            $router.push({
              name: 'guidelines',
              params: { transition: 'slide-forward' },
            })
          "
          class="w-full px-6 py-3 text-lg text-left rounded-full text-m ring-gray-300 ring-1 disabled:text-gray-200"
        >
          Del et nytt sted <span v-show="!user">🔒</span>
        </button>
        <button
          :disabled="!user"
          @click="
            $router.push({
              name: 'locations',
              params: { transition: 'slide-forward' },
            })
          "
          class="w-full px-6 py-3 text-lg text-left rounded-full text-m ring-gray-300 ring-1"
        >
          Mine steder <span v-show="!user">🔒</span>
        </button>
        <button
          @click="
            $router.push({
              name: 'faq',
              params: { transition: 'slide-forward' },
            })
          "
          class="w-full px-6 py-3 text-lg text-left rounded-full text-m ring-gray-300 ring-1"
        >
          Spørsmål og svar
        </button>
        <button
          @click="
            $router.push({
              name: 'about',
              params: { transition: 'slide-forward' },
            })
          "
          class="w-full px-6 py-3 text-lg text-left rounded-full text-m ring-gray-300 ring-1"
        >
          Om Telt
        </button>
        <button
          @click="
            $router.push({
              name: 'privacy',
              params: { transition: 'slide-forward' },
            })
          "
          class="w-full px-6 py-3 text-lg text-left rounded-full text-m ring-gray-300 ring-1"
        >
          Personvern
        </button>
      </div>
      <div class="mt-6">
        <button
          @click="$router.push({ name: 'home', params: { transition: 'slide-bottom' } })"
          class="text-lg rounded-full w-14 h-14 ring-gray-300 ring-1"
        >
          <i class="fa-light fa-xmark fa-lg"></i>
        </button>
      </div>
    </div>
  </card-container>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import CardContainer from '../components/CardContainer.vue'

export default {
  components: { CardContainer },

  setup() {
    const store = useStore()

    const signOut = () => {
      store.dispatch('signOut')
    }

    return {
      signOut,
      user: computed(() => store.getters.user),
    }
  },
}
</script>
